<template>
  <div class="row">
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
      <c-card title="기본안전보건대장" class="cardClassDetailForm">
        <template slot="card-detail">
          <div class="col-12">
            <c-upload
              :attachInfo="attachInfo1"
              :editable="editable"
              label="기본안전보건대장">
            </c-upload>
          </div>
        </template>
      </c-card>
    </div>
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
      <c-card title="설계안전보건대장" class="cardClassDetailForm">
        <template slot="card-detail">
          <div class="col-12">
            <c-upload
              :attachInfo="attachInfo2"
              :editable="editable"
              label="설계안전보건대장">
            </c-upload>
          </div>
        </template>
      </c-card>
    </div>
  </div>
</template>
<script>
export default {
  name: 'project-book-attach',
  props: {
    param: {
      type: Object,
      default: () => ({
        plantCd: '',
        updateFlag: '',
      }),
    },
  },
  data() {
    return {
      editable: false,
      attachInfo1: {
        isSubmit: '',
        taskClassCd: 'PROJECT_BOOK_BASIC',
        taskKey: '',
      },
      attachInfo2: {
        isSubmit: '',
        taskClassCd: 'PROJECT_BOOK_DESIGN',
        taskKey: '',
      },
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
  },
  methods: {
    init() {
      // role setting
      if(this.$store.getters.user.innerFlag === 'N') {
        this.editable = false;
      } else {
        this.editable = this.$route.meta.editable;
      }
      this.$set(this.attachInfo1, 'taskKey', this.param.plantCd)
      this.$set(this.attachInfo2, 'taskKey', this.param.plantCd)
    },
  }
};
</script>